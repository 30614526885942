import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import visualCollaboration from "images/visual-collaboration.svg";
import prototypeIllustrationImageSrc from "images/sustainable-farming.svg";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Fire from "./images/fire.svg";
import ThumbsUp from "./images/thumbs-up.svg";
import { NAV_LINKS } from "./constants";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton />
      <div id={NAV_LINKS.HOW_IT_WORKS}>
        <FeatureWithSteps
          subheading={<Subheading>3D Build Process</Subheading>}
          heading={
            <>
              How it <HighlightedText>Works.</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={visualCollaboration}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          steps={[
            {
              heading: "Design",
              description: "We work diligently with you to design a product that meets your specific needs as a business. Don't know exactly what you want? We'll help you figure it out."
            },
            {
              heading: "Develop",
              description: "Your new UI is built in a separate environment without any downtime for your current application. Our development includes highly visible code progress, viewable throughout the dev process."
            },
            {
              heading: "Deploy",
              description: "After the new frontend is built and quality tested, we will deploy it to production and replace your existing UI. We don't put it into production until you say it's ready."
            },
          ]}
        />
      </div>
      <div id={NAV_LINKS.SERVICES}>
        <Features
          subheading={<Subheading>Services</Subheading>}
          heading={
            <>
              What can we <HighlightedText>flip?</HighlightedText>
            </>
          }
          description={''}
        />
      </div>

      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Donate <HighlightedText>10%</HighlightedText> to Your Choice Charity
          </>
        }
        description={"Giving back to the world is our ultimate goal as a company. Building a community that gives back is our second priority! We donate 10% of our profits from your project to a charity of your choice."}
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: ThumbsUp,
            title: "Integrity",
            description: "Our code is honor and trust. We stand by our principles and don't sacrifice on our ethics.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: Fire,
            title: "Passion",
            description: "Passion is our driving force. Your UI transform is equally exciting for us as it is for you.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      <div id={NAV_LINKS.FAQ}>
        <FAQ
          subheading={<Subheading>FAQ</Subheading>}
          heading={
            <>
              Have any <HighlightedText>Questions?</HighlightedText>
            </>
          }
          description="Don't see an answer to your question below? Send us a message!"
          faqs={[
            {
              question: "Why should I trust FrontFlip with my project?",
              answer:
                "Our applications are built to the highest React coding standards. You will have access to view our app as it's built so you can give feedback during development, as well as give feedback on anything you want to change as it's built. You can trust us to get the job done efficiently, affordably, and ethically."
            },
            {
              question: "Why do you build with React?",
              answer:
                "In addition to being to most popular JavaScript UI library, React is a great option for unifying all your UI related code. React has a \"learn once write anywhere\" mentality that supports building frontends for web, mobile, tablet, and desktop applications. While it's not 100% transferrable code between devices, it's highly scalable and we find that roughly 60% of code can be transferred between devices. What does this mean for you? A single React developer can work on all your entire UI codebase. Apps are also much quicker to build for other platforms, as much of the code base is shareable. The combined speed of development between a smaller team and shared code base results in quick to ship code that is cheaper for you to scale and build."
            },
            {
              question: "Do you build full applications?",
              answer:
                "Yes. We will build your app (both frontend and backend) from the ground up. Reach out and let us know what you have in mind!"
            },
            {
              question: "What other coding tools do you use to build?",
              answer:
                "In addition to React, we prefer to use TypeScript, NodeJS, REST, GQL, Firebase, Google Cloud, and AWS. We stick with the JavaScript stack to lower the level of complexity to one single language. We choose the simple route without sacrificing quality."
            },
            {
              question: "Can I hire your developers to do contract React work?",
              answer:
                "Absolutely. Reach out and we'll give you an hourly quote."
            },
            {
              question: "Can I cancel a project?",
              answer:
                "At any point during the process you can choose to stop development. If you find someone cheaper, you like better, or simply need to halt progress on your app for any reason it's totally fine! You can also resume your project or shift progress to a new one at anytime as well. You only pay for the time we've put in. We're very accomodating and flexible."
            },
          ]}
        />
      </div>
      <div id={NAV_LINKS.CONTACT}>
        <TwoColContactUsWithIllustrationFullForm />
      </div>
      <Footer />
    </AnimationRevealPage>
  );
}
