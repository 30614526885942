export const NAV_LINKS = {
  HOME: 'home',
  HOW_IT_WORKS: 'howItWorks',
  FAQ: 'faq',
  CONTACT: 'contact',
  SERVICES: 'services'
};

export const EMAIL_JS = {
  SERVICE_ID: 'service_jh95tdg',
  TEMPLATE_ID: 'template_puxkef3',
  USER_ID: 'user_Evn8G2kGBlGqbZ1gccvbu'
}