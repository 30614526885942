import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import InterviewImg from "images/interview.svg";
import emailjs from 'emailjs-com';
import { EMAIL_JS } from "../../constants";
import Snackbar from "components/Snackbar";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact",
  heading = <>Feel free to <span tw="text-primary-500">reach out.</span><wbr /></>,
  description = "We love hearing about new and exciting projects. Let us know what's on your mind, or drop a line to say what's up!",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  function sendEmail(e) {
    e.preventDefault();

    if (!email) return;

    emailjs.sendForm(EMAIL_JS.SERVICE_ID, EMAIL_JS.TEMPLATE_ID, e.target, EMAIL_JS.USER_ID)
      .then((result) => {
        console.log(result.text);
        setNotiOpen(true);
        setEmail('');
        setName('');
        setMessage('');
      }, (error) => {
        console.log(error.text);
      });
  }
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [notiOpen, setNotiOpen] = useState(false);

  return (
    <>
      <form onSubmit={sendEmail} >
        <Container>
          <TwoColumn>
            <ImageColumn>
              <Image imageSrc={InterviewImg} alt='Contact Us' />
            </ImageColumn>
            <TextColumn textOnLeft={textOnLeft}>
              <TextContent>
                {subheading && <Subheading>{subheading}</Subheading>}
                <Heading>{heading}</Heading>
                {description && <Description>{description}</Description>}
                <Form action={formAction} method={formMethod}>
                  <Input value={email} onChange={e => setEmail(e.target.value)} type="email" name="user_email" placeholder="Your Email Address" ariaLabel='email' />
                  <Input value={name} onChange={e => setName(e.target.value)} type="text" name="user_name" placeholder="Full Name" ariaLabel='username' />
                  <Textarea value={message} onChange={e => setMessage(e.target.value)} name="message" placeholder="Your Message Here" ariaLabel='message' />
                  <SubmitButton type="submit">{submitButtonText}</SubmitButton>
                </Form>
              </TextContent>
            </TextColumn>
          </TwoColumn>
        </Container>
      </form>
      <Snackbar open={notiOpen} onClose={() => setNotiOpen(false)} />
    </>
  );
};
