import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import MarketFit from "../../images/market-fit.svg";
import { EMAIL_JS } from "../../constants.js";
import emailjs from 'emailjs-com';
import Snackbar from "components/Snackbar.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col-reverse lg:flex-row lg:items-center max-w-screen-xl mx-auto lg:py-20 md:py-24 `;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left md:mt-0 mt-8`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end md:mb-8 sm:mb-8`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

export default ({ roundedHeaderButton }) => {

  const [email, setEmail] = useState('');

  function sendEmail(e) {
    e.preventDefault();

    if (!email) return;

    emailjs.sendForm(EMAIL_JS.SERVICE_ID, EMAIL_JS.TEMPLATE_ID, e.target, EMAIL_JS.USER_ID)
      .then((result) => {
        setEmail('')
        setNotiOpen(true)
      }, (error) => {
        console.log(error.text);
      });
  }

  const [notiOpen, setNotiOpen] = useState(false);

  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              We'll <span tw="text-primary-500">Flip</span> Your Old Frontend and Rebuild it in <span tw="text-primary-500">React</span>
            </Heading>
            <Paragraph>
              Have an old UI that's expensive, slow, and difficult to maintain?
              Let us rebuild it for you from the ground up using React!
            </Paragraph>
            <form onSubmit={sendEmail} >
              <Actions>
                <input value={email} onChange={e => setEmail(e.target.value)} name="user_email" placeholder="Your E-mail Address" />
                <button type='submit'>I'm interested!</button>
              </Actions>
            </form>
            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={MarketFit} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
      <Snackbar open={notiOpen} onClose={() => setNotiOpen(false)} />
    </>
  );
};
